@import "ui-components/_variables.scss";

:export {
  tiny: strip-unit(breakpoint("tiny"));
  small: strip-unit(breakpoint("small"));
}

.hero {
  height: 600px;
  width: 100%;
  overflow: hidden;

  img {
    object-fit: cover;
    object-position: top center;
    width: 100%;
  }

  .image {
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .content {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: var(--invertedText);

    & > h1 {
      margin-bottom: size("gutter--huge");
    }

    & > p {
      margin-top: 0;
      margin-bottom: size("gutter--larger");
    }

    @media (max-width: (breakpoint("medium") - 1px)) {
      & > h1 {
        font-size: size("font--huge");
        margin-bottom: size("gutter--larger");
      }
    }

    @media (max-width: (breakpoint("tiny") - 1px)) {
      top: 30%;
      left: unset;
      transform: none;
      margin: 0 size("gutter--large");

      & > h1 {
        font-size: size("font--large");
      }
    }
  }

  .customer_badge {
    position: absolute;
    top: size("header-height");
    left: size("gutter--small");
    color: var(--invertedText);

    &:hover {
      text-decoration: none;

      p:first-child {
        text-decoration: underline;
      }
    }

    @media (min-width: breakpoint("tiny")) {
      display: none;
    }
  }
  .heroIcon {
    max-width: 60%;

    @media (max-width: breakpoint("tiny")) {
      max-width: 80%;
    }
  }

  .heroLogo {
    position: absolute;
    bottom: 0;
    right: 0;
    margin: size("gutter--large");
    width: 35%;

    img {
      display: block;
      max-width: 450px;
      margin: auto;
    }
  }
}
